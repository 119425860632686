<template>
  <NotFound />
</template>

<script>
import NotFound from '@/components/ErrorPages/NotFound/NotFound.vue';

export default {
  components: {
    NotFound,
  },
};
</script>
